import { ErrorMessage, Field } from "formik";
import * as Yup from "yup";

export const schema = () => {
  const schema = Yup.object().shape({
    idnumber: Yup.string().required("كود الاستبيان مطلوب ."),
  });
  return schema;
};

export const InputOuter = (props) => {
  const { Error, Value, Type, Name, Placeholder } = props;

  return (
    <div className={Error ? `form-group position-relative` : `form-group`}>
      <Field
        type={Type}
        component="input"
        placeholder={Placeholder}
        value={Value}
        className={Error ? "form-control is-invalid" : "form-control"}
        name={Name}
      />

      <ErrorMessage name={Name} component="div" className="errorfiled" />
    </div>
  );
};
