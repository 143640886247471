import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import Cookies from "js-cookie";

function StaffGuard({ children }) {
  const [token, setToken] = useState("");
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const token = Cookies.get("staff-number");
    if (token) {
      setToken(token);
    }
    setTimeout(() => setLoading(false), 50);
  }, []);

  useEffect(() => {
    if (loading) return;
    if (!token && location.pathname !== "/staff-signin")
      navigate("/staff-signin");
    else if (location.pathname === "/staff-signin" && token)
      navigate("/staff-survey");
  }, [token, children, navigate, location.pathname, loading]);

  return <>{!loading && children}</>;
}

export default StaffGuard;
