import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import axiosInstance from "../../../../api/axios_instance";
import { BACKEND_URL } from "../../../../utils/headers";
import swal from "sweetalert";

const DetailsEnd = (props) => {
  const id = Cookies.get("testid-study");
  const [accepted, setAccepted] = useState(false);
  const [loading, setLoading] = useState(true);

  const { show } = props;
  const NextPage = () => {
    if (accepted) window.location.href = `/questions`;
  };
  useEffect(() => {
    const StudentId = localStorage.getItem("user-study-study");
    const testId = Cookies.get("testid-study");
    GetAgreementStatus(StudentId, testId, setAccepted, setLoading);
  }, []);

  return (
    <div className="details__content__end">
      {show && (
        <button
          className="btn btn-logout"
          type="button"
          onClick={NextPage}
          data-bs-toggle="modal"
          data-bs-target={`#AgreementModal-${id}`}
          disabled={loading}
        >
          بدء الاختبار
        </button>
      )}
      <div style={{ display: !accepted ? "block" : "none" }}>
        <AgreementModal />
      </div>
    </div>
  );
};

export default DetailsEnd;
const AgreementModal = (props) => {
  const StudentId = localStorage.getItem("user-study-study");
  const id = Cookies.get("testid-study");
  const [isAccepted, setIsAccepted] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [agreement, setAgreement] = useState(false);

  useEffect(() => {
    GetData(setAgreement, () => {});
  }, []);
  const handleCheckboxChange = (e) => {
    setIsAccepted(e.target.checked);
  };
  return (
    <div
      className="modal fade modal-edite-exams"
      id={`AgreementModal-${id}`}
      tabIndex="-1"
      aria-labelledby="AgreementModal"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div
            className="header"
            style={{ background: "#264b2a", color: "white" }}
          >
            <h3>الشروط و الاحكام</h3>
          </div>
          <div
            className="ql-editor"
            style={{
              maxHeight: "400px",
              overflowY: "auto",
              width: "100%",
              background: "#ececec",
              borderRadius: "5px",
            }}
            dangerouslySetInnerHTML={{ __html: agreement }}
          ></div>
          <div>
            <input
              type="checkbox"
              id="acceptPolicyCheckbox"
              checked={isAccepted}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="acceptPolicyCheckbox" style={{ padding: "0 10px" }}>
              اوافق على الشروط والاحكام
            </label>
            {!isAccepted && submitted && (
              <div style={{ color: "#f87583" }}>برجاء التاكيد على الموافقة</div>
            )}
          </div>

          <div className="form-group-button">
            <button
              style={{
                width: "162px",
                height: "51px",
                background: "#ececec",
                opacity: 0,
                pointerEvents: "none",
              }}
              type="button"
              className="btn btn-back"
              data-bs-dismiss="modal"
            >
              رفض
            </button>
            <button
              type="button"
              className="btn btn-logout"
              onClick={(e) => {
                e.preventDefault();
                if (!submitted) setSubmitted(true);
                if (isAccepted) {
                  UpdateAgreementStatus(StudentId, id);
                }
              }}
            >
              قبول
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
const GetData = async (setData, setLoading) => {
  setLoading(false);
  const options = {
    method: "get",
    url: `${BACKEND_URL}Setting/GetAgreementValue`,
    headers: Headers,
  };
  await axiosInstance(options)
    .then(function (response) {
      if (response.data.responseStatus.description === "Error") {
        swal(response.data.responseStatus.errors[0].errorMessage, {
          icon: "error",
          confirmButtonColor: "#d33",
          button: {
            text: "إغلاق",
            closeModal: true,
          },
        }).then((result) => {});
        return;
      }
      setData(response.data.responseObject);
      setLoading(true);
    })
    .catch(function (error) {
      setLoading(true);
    });
};
const GetAgreementStatus = async (
  studentId,
  testId,
  setAgreementStatus,
  setLoading
) => {
  const options = {
    method: "post",
    url: `${BACKEND_URL}Test/GetStudentAgreement`,
    headers: Headers,
    data: { studentId, testId },
  };
  await axiosInstance(options)
    .then(function (response) {
      if (response.data.responseStatus.description === "Error") {
        swal(response.data.responseStatus.errors[0].errorMessage, {
          icon: "error",
          confirmButtonColor: "#d33",
          button: {
            text: "إغلاق",
            closeModal: true,
          },
        }).then((result) => {
          window.location.href = "/";
        });
        return;
      }
      setAgreementStatus(response.data.responseObject);
      setLoading(false);
    })
    .catch(function (error) {
      setLoading(false);
    });
};
const UpdateAgreementStatus = async (studentId, testId) => {
  const options = {
    method: "post",
    url: `${BACKEND_URL}Test/SaveStudentAgreement`,
    headers: Headers,
    data: { studentId, testId, isAgree: true },
  };
  await axiosInstance(options)
    .then(function (response) {
      if (response.data.responseStatus.description === "Error") {
        swal(response.data.responseStatus.errors[0].errorMessage, {
          icon: "error",
          confirmButtonColor: "#d33",
          button: {
            text: "إغلاق",
            closeModal: true,
          },
        }).then((result) => {
          window.location.href = "/";
        });
        return;
      }
      window.location.href = "/questions";
    })
    .catch(function (error) {});
};
