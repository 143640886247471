const DetailsData = (props) => {
  const {
    Subject,
    Code,
    NumberOfQuestions,
    TheDate,
    TestDuration,
    TestStartTime,
    TestEndTime,
    matchingDegrees,
    trueFalseDegrees,
    choiceDegrees,
    data,
  } = props;
  const NewDay = TheDate.split("T")[0].replace(/-/g, "/");

  const MyArrayEndTime = TestEndTime.split(" "),
    MyArrayStartTime = TestStartTime.split(" ");

  return (
    <div className="details__content__data">
      <ul>
        <li>
          <span className="title">المادة</span>
          <span className="data">
            <span className="bold">{Subject}</span> -{" "}
            <span className="samil">{Code}</span>
          </span>
        </li>
        <li>
          <span className="title">عدد الاسئلة</span>
          <span className="data">
            <span className="bold">{NumberOfQuestions}</span>
          </span>
        </li>
        <li>
          <span className="title">التاريخ</span>
          <span className="data">
            <span className="bold">{NewDay}</span>
          </span>
        </li>
        <li>
          <span className="title">مدة الاختبار</span>
          <span className="data">
            <span className="bold">{TestDuration}</span>
          </span>
        </li>
        <li>
          <span className="title">وقت بداية الاختبار</span>
          <span className="data">
            <span className="bold d-flex">
              <span>{MyArrayStartTime[1]}</span>
              <span>{MyArrayStartTime[0]}</span>
            </span>
          </span>
        </li>
        <li>
          <span className="title">وقت نهاية الاختبار</span>
          <span className="data">
            <span className="bold d-flex">
              <span>{MyArrayEndTime[1]}</span>
              <span>{MyArrayEndTime[0]}</span>
            </span>
          </span>
        </li>
        <li>
          <span className="title"> اسئلة التوصيل</span>
          <span className="data">
            <span className="bold d-flex">
              <span>{matchingDegrees.toFixed(2)}</span>
              <span>{"درجة"}</span>( <span>{"عدد الاسئلة: "}</span>
              <span>{data.matchingCount}</span>)
            </span>
          </span>
        </li>
        <li>
          <span className="title"> الاسئلة الاختياري</span>
          <span className="data">
            <span className="bold d-flex">
              <span>{choiceDegrees.toFixed(2)}</span>
              <span>{"درجة"}</span>( <span>{"عدد الاسئلة: "}</span>
              <span>{data.choiceCount}</span>)
            </span>
          </span>
        </li>
        <li>
          <span className="title"> اسئلة الصح والخطأ</span>
          <span className="data">
            <span className="bold d-flex">
              <span>{trueFalseDegrees.toFixed(2)}</span>
              <span>{"درجة"}</span>( <span>{"عدد الاسئلة: "}</span>
              <span>{data.trueFalseCount}</span>)
            </span>
          </span>
        </li>
      </ul>
    </div>
  );
};

export default DetailsData;
