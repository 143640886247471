import Cookies from "js-cookie";
import QuestionsTop from "./components/questions-top";
import QuestionsContainer from "./components/questions-container/index";
import { useEffect, useState } from "react";
import { requestHeaders, BACKEND_URL } from "../../utils/headers";
import swal from "sweetalert";
import Loading from "../../components/loading/loading";
import { AddQuestions } from "./components/value";
import axiosInstance from "../../api/axios_instance";

export default function Questions() {
  const [matchingQuestions, setMatchingQuestions] = useState([]);
  const [nonMatchingQuestions, setNonMatchingQuestions] = useState([]);
  const [subjectName, setSubjectName] = useState("");
  const [subjectCode, setSubjectCode] = useState("");

  const [lastAnsweredQuestionOrder, setLastAnsweredQuestionOrder] = useState(0);
  const [duration, setDuration] = useState(0);
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(0);
  const [load, setLoad] = useState(true);
  const [Questions, setQuestions] = useState([]);
  const [degrees, setDegrees] = useState({});
  const [agreement, setAgreement] = useState(null);

  useEffect(() => {
    setStep(
      lastAnsweredQuestionOrder - 1 <= 0
        ? 0
        : lastAnsweredQuestionOrder >= Questions.length
        ? Questions.length - 1
        : lastAnsweredQuestionOrder - 1
    );
  }, [Questions, lastAnsweredQuestionOrder]);

  const onBeforeUnload = (ev) => {
    return (ev.returnValue = "اذاا غادرت فلن تستطيع العودة بدون اذن المشرف.");
  };
  useEffect(() => {
    if ((load, agreement))
      window.addEventListener("beforeunload", onBeforeUnload);
    return () => window.removeEventListener("beforeunload", onBeforeUnload);
  }, [load, agreement]);
  useEffect(() => {
    const StudentId = localStorage.getItem("user-study-study");
    const TestId = Cookies.get("testid-study");
    GetAgreementStatus(StudentId, TestId, setAgreement, () => {});
  }, []);
  useEffect(() => {
    const StudentId = localStorage.getItem("user-study-study");
    const TestId = Cookies.get("testid-study");
    if (!TestId) {
      window.location.href = "/";
      return;
    }
    if (agreement !== null && agreement !== undefined) {
      if (!agreement && false) {
        swal("برجاء الموافقة على الشروط والاحكام للبدء بالاختبار", {
          icon: "warning",
          timer: 2000,
          className: "swal-contact-us",
        }).then(() => {
          window.location.href = "/details/" + TestId;
        });
        return;
      }
      GetData(
        StudentId,
        TestId,
        setSubjectCode,
        setSubjectName,
        setMatchingQuestions,
        setNonMatchingQuestions,
        setLoading,
        setQuestions,
        setStep,
        setLastAnsweredQuestionOrder,
        setDuration
      );
      getDegrees(StudentId, TestId, setDegrees);
    }
  }, [agreement]);

  if (loading === false || !Questions.length)
    return (
      <section className="questions">
        <Loading />
      </section>
    );

  return (
    <section className="questions">
      <div className="container">
        <div className="questions__content">
          <QuestionsTop
            duration={duration}
            setLoad={setLoad}
            subjectName={subjectName}
            subjectCode={subjectCode}
            End={step}
            Questions={Questions}
          />

          {Questions.length && (
            <QuestionsContainer
              degrees={degrees}
              key={JSON.stringify(Questions)}
              MatchingQuestions={matchingQuestions}
              NonMatchingQuestions={nonMatchingQuestions}
              Questions={Questions}
              Step={step}
              setStep={setStep}
            />
          )}
        </div>
      </div>
    </section>
  );
}
const getDegrees = async (StudentId, TestId, setDegrees) => {
  var data = new FormData();
  data.append("studentId", StudentId);
  data.append("testId", TestId);
  const options = {
    method: "post",
    url: `${BACKEND_URL}Test/GetQuestionTypesDegrees`,
    headers: requestHeaders,
    data,
  };
  await axiosInstance(options).then((response) => {
    setDegrees(response.data.responseObject);
  });
};
const GetData = async (
  StudentId,
  TestId,
  setSubjectCode,
  setSubjectName,
  setMatchingQuestions,
  setNonMatchingQuestions,
  setLoading,
  setQuestions,
  setStep,
  setLastAnsweredQuestionOrder,
  setDuration
) => {
  var data = new FormData();
  data.append("studentId", StudentId);
  data.append("testId", TestId);
  const options = {
    method: "post",
    url: `${BACKEND_URL}Test/StartTest`,
    headers: requestHeaders,
    data,
  };
  await axiosInstance(options)
    .then(function (response) {
      const startDate = new Date(response.data.responseObject.testStartDate);
      const endDate = new Date(response.data.responseObject.testEndDate);
      setDuration((endDate - startDate) / 1000);
      setSubjectCode(response.data.responseObject.subjectCode);
      setSubjectName(response.data.responseObject.subjectName);
      AddQuestions(
        response.data.responseObject.matchingQuestions,
        response.data.responseObject.nonMatchingQuestions,
        setQuestions
      );
      setMatchingQuestions(response.data.responseObject.matchingQuestions);
      setNonMatchingQuestions(
        response.data.responseObject.nonMatchingQuestions
      );
      setLastAnsweredQuestionOrder(
        response.data.responseObject.lastAnsweredQuestionOrder
      );
      setLoading(true);
    })
    .catch(function (error) {
      setLoading(true);
    });
};

const GetAgreementStatus = async (
  studentId,
  testId,
  setAgreementStatus,
  setLoading
) => {
  setLoading(false);
  const options = {
    method: "post",
    url: `${BACKEND_URL}Test/GetStudentAgreement`,
    headers: Headers,
    data: { studentId, testId },
  };
  await axiosInstance(options)
    .then(function (response) {
      if (response.data.responseStatus.description === "Error") {
        swal(response.data.responseStatus.errors[0].errorMessage, {
          icon: "error",
          confirmButtonColor: "#d33",
          button: {
            text: "إغلاق",
            closeModal: true,
          },
        }).then((result) => {});
        return;
      }
      setAgreementStatus(!!response.data.responseObject);
      setLoading(false);
    })
    .catch(function (error) {
      setLoading(false);
    });
};
