/* eslint-disable eqeqeq */
import { Formik } from "formik";
//import { useRouter } from "next/router";
import { useState } from "react";
import { InputOuter, schema } from "./form-data";
import { Headers } from "../../../api/actions";
import { Api } from "../../../api/index";
import axios from "axios";
import { signin } from "../../../api/axios_instance";
import Cookies from "js-cookie";

export function LoginForm() {
  const [massegeerror, setMassegeError] = useState("");
  const state = {
    idnumber: "",
  };

  const form = (props) => {
    return (
      <form onSubmit={props.handleSubmit}>
        <InputOuter
          Error={props.errors.idnumber}
          Value={props.values.idnumber}
          Type={"text"}
          Name={"idnumber"}
          Placeholder={"كود الاستبيان"}
        />
        {massegeerror && (
          <div className="errorfiled">
            <p>{massegeerror}</p>
          </div>
        )}

        <button type="submit" className={"btn"}>
          ابدأ الاستبيان
        </button>
      </form>
    );
  };

  const SignIn = (values) => {
    OnSubmit(values.idnumber, setMassegeError);
  };
  return (
    <div className="login__form">
      <Formik
        initialValues={state}
        onSubmit={SignIn}
        render={form}
        validationSchema={schema()}
        validateOnChange={false}
        enableReinitialize={true}
        validateOnBlur={false}
      />
    </div>
  );
}

const OnSubmit = async (idnumber, setMassegeError) => {
  Cookies.set("staff-number", idnumber);
  window.location.reload();
};
