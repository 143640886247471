import Cookies from "js-cookie";
import { useState, useEffect } from "react";
import { requestHeaders, BACKEND_URL } from "../../utils/headers";
import axios from "axios";
import Loading from "../../components/loading/loading";
import EvaluationContent from "./components";
import { AddEvaluation } from "./components/value";
import axiosInstance from "../../api/axios_instance";

export default function StaffSurvey() {
  const [loading, setLoading] = useState(false);
  const [Questions, setQuestions] = useState([]);

  useEffect(() => {
    GetData(setLoading, setQuestions);
  }, []);

  if (loading === false)
    return (
      <section className="questions">
        <div className="container">
          <Loading />
        </div>
      </section>
    );

  return (
    <section className="questions">
      <div className="container">
        {Questions?.length && <EvaluationContent questions={Questions} />}
      </div>
    </section>
  );
}

const GetData = async (setLoading, setQuestions) => {
  const options = {
    method: "post",
    url: `${BACKEND_URL}Survey/GetStaffSurvey`,
    headers: requestHeaders,
  };
  await axiosInstance(options)
    .then(function (response) {
      if (!response.data.responseObject?.length)
        window.location.href = "/end-page";
      else {
        setQuestions(
          response.data.responseObject.map((q) => ({
            id: q.questionId,
            questionName: q.questionName,
            type: q.questionTypeId,
            answer: "",
          }))
        );
        setLoading(true);
      }
    })
    .catch(function (error) {
      setLoading(true);
    });
};
