import axios from "axios";
import Cookies from "js-cookie";
import swal from "sweetalert";

const axiosInstance = axios.create();
export const signout = () => {
  localStorage.removeItem("user-study-study");
  Cookies.remove("user-study-name");
  Cookies.remove("user-study-token");
  Cookies.remove("classroom-study");

  Cookies.remove("testid-study");
  Cookies.remove("testSubjectName-study");
  Cookies.remove("testSubjectCode-study");
  Cookies.remove("testEndTime-study");
  Cookies.remove("subjectName-study");

  localStorage.removeItem("fullName-study");
  window.location.href = "/signin";
};

export const signin = (response) => {
  Cookies.remove("user-study-token");
  Cookies.set("user-study-token", response.data.responseObject.token);
  localStorage.setItem(
    "user-study-study",
    response.data.responseObject.studentId
  );
  Cookies.remove("user-study-name");
  Cookies.set("user-study-name", response.data.responseObject.fullName);

  localStorage.removeItem("fullName-study");
  localStorage.setItem("fullName-study", response.data.responseObject.fullName);

  window.location.href = "/";
};
axiosInstance.interceptors.request.use(
  (config) => {
    const token = Cookies.get("user-study-token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    if (
      response.data.responseStatus.code === 2 ||
      response.data.responseStatus.code === 3
    ) {
      swal(
        response.data.responseStatus.errors
          .map((err) => err.errorMessage)
          .join("\n"),
        {
          icon: "warning",
          timer: 2000,
          className: "swal-contact-us",
        }
      ).then(() => {
        signout();
      });
    } else return response;
  },
  (error) => {
    if (error?.response?.status === 401) {
      signout();
    }
  }
);

export default axiosInstance;
