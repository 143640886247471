import ListQuestions from "./list-questions";
import { requestHeaders, BACKEND_URL } from "../../../../utils/headers";

import Cookies from "js-cookie";
import axios from "axios";
import swal from "sweetalert";
import axiosInstance from "../../../../api/axios_instance";
import { useNavigate } from "react-router";

const QuestionsMyChoice = (props) => {
  const { Quetsions, Step, setStep, setActive, degrees } = props;

  return (
    <>
      <div className="questions__content__container">
        <div className="questions__content__container__content">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h3>سؤال رقم {Step + 1}</h3>
            <h3
              style={{
                padding: "10px",
                borderRadius: "10px",
                background: "#ececec",
              }}
            >
              {(degrees?.[Quetsions?.[Step]?.degreeType] || 0).toFixed(2)} درجة
            </h3>
          </div>
          <h3>{Quetsions[Step].question[0].questionName}</h3>
          <span className="line"></span>
          <div className="questions__content__container__content__answers-one">
            {Quetsions[Step].answers.map((item, index) => {
              return (
                <MyChoiceAnswers
                  item={item}
                  index={index}
                  key={index}
                  Question={Quetsions[Step].question[0]}
                  ArrayAnswerssData={Quetsions[Step].answers}
                  Step={Step}
                  Quetsions={Quetsions}
                />
              );
            })}
          </div>
        </div>

        <ListQuestions Quetsions={Quetsions} Step={Step} />
      </div>
      <QuestionsEnd
        Quetsions={Quetsions}
        Step={Step}
        setStep={setStep}
        setActive={setActive}
      />
    </>
  );
};
export default QuestionsMyChoice;

const MyChoiceAnswers = (props) => {
  const { item, index, Question, ArrayAnswerssData, Step, Quetsions } = props;

  const handleChange = (e) => {
    e.preventDefault();
    RemoveData(ArrayAnswerssData);
    e.currentTarget.classList.add("active");
    ArrayAnswerssData[index].isSelectedByStudent = true;
    Quetsions[Step].isSelect = true;
    Quetsions[Step].EndAnswer[0].questionId = Question.questionId;
    Quetsions[Step].EndAnswer[0].answerId = item.answerId;
  };

  return (
    <button
      type="button"
      className={`questions__content__container__content__answers-one__item ${
        item.answerId === Quetsions[Step].EndAnswer[0].answerId ? "active" : ""
      }`}
      onClick={(e) => {
        handleChange(e);
      }}
    >
      <label>
        <span className="creacil">
          <span className="checkmark"></span>
        </span>
        {item.answerName}
      </label>
    </button>
  );
};

const QuestionsEnd = (props) => {
  const { Quetsions, Step, setStep, setActive } = props;

  const TestId = Cookies.get("testid-study");
  const navigate = useNavigate();
  const handleNext = () => {
    if (
      Quetsions[Step].EndAnswer[0].questionId === "" ||
      Quetsions[Step].EndAnswer[0].answerId === ""
    ) {
      swal("يجب عليك اختيار اجابة", {
        icon: "warning",
        timer: 1000,
        className: "swal-contact-us",
      });
      return false;
    } else {
      CheckStep(
        Quetsions,
        Step,
        setStep,
        Quetsions[Step].EndAnswer,
        setActive,
        navigate
      );
    }
  };

  return (
    <div className="questions__content__container__end">
      <button
        className="btn btn-back"
        type="button"
        style={{
          opacity: Step == 0 ? "0" : "1",
          pointerEvents: Step == 0 ? "none" : "auto",
        }}
        onClick={() => {
          if (Step === 0) {
            // navigate(`/details/${TestId}`);
          } else {
            setStep(Step - 1);
          }
        }}
      >
        <IconBack />
        <span className="text">السابق</span>
      </button>
      <button
        className="btn btn-next"
        type="button"
        onClick={() => {
          handleNext();
        }}
      >
        <span className="text">التالى</span>
        <IconNext />
      </button>
    </div>
  );
};

const CheckStep = (
  Quetsions,
  Step,
  setStep,
  Arrayquestion,
  setActive,
  navigate
) => {
  const StudentId = localStorage.getItem("user-study-study");
  const TestId = Cookies.get("testid-study");

  SendData(
    StudentId,
    TestId,
    Step,
    setStep,
    Quetsions,
    Arrayquestion,
    setActive,
    navigate
  );
};
const SendData = async (
  StudentId,
  TestId,
  Step,
  setStep,
  Quetsions,
  Arrayquestion,
  setActive,
  navigate
) => {
  var data = new FormData();
  data.append("studentId", StudentId);
  data.append("testId", TestId);
  for (let i = 0; i < Arrayquestion.length; i++) {
    data.append(`testAnswers[${i}].questionId`, Arrayquestion[i].questionId);
    data.append(`testAnswers[${i}].answerId`, Arrayquestion[i].answerId);
  }

  const options = {
    method: "post",
    url: `${BACKEND_URL}Test/SaveStudentAnswer`,
    headers: requestHeaders,
    data,
  };
  await axiosInstance(options)
    .then(function (response) {
      if (response.data.responseStatus.description === "Success") {
        if (Step === Quetsions.length - 1) {
          navigate("/end-questions");
        } else {
          const number = +Step;
          setStep(number + 1);
          setActive(true);
        }
      } else {
        const Error = response.data.responseStatus.errors[0].errorMessage;
        swal(Error, {
          icon: "error",
          timer: 1000,
          className: "swal-contact-us",
        });
      }
    })
    .catch(function (error) {
      const Error = error.response.data.Errors.TestAnswers[1];
      swal(Error, {
        icon: "error",
        timer: 1000,
        className: "swal-contact-us",
      });
    });
};
const RemoveData = (ArrayAnswerssData) => {
  const Array = document.querySelectorAll(
    ".questions__content__container__content__answers-one__item"
  );
  Array.forEach((element) => {
    element.classList.remove("active");
  });

  ArrayAnswerssData.forEach((element) => {
    element.isSelectedByStudent = false;
  });
};

const IconNext = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
    >
      <path
        d="M19 8.3125H4.54812L11.1803 1.68031L9.5 0L0 9.5L9.5 19L11.1803 17.3197L4.54812 10.6875H19V8.3125Z"
        fill="white"
      />
    </svg>
  );
};

const IconBack = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
    >
      <path
        d="M0 10.6875L14.4519 10.6875L7.81969 17.3197L9.5 19L19 9.5L9.5 0L7.81969 1.68031L14.4519 8.3125L0 8.3125V10.6875Z"
        fill="#B7BBC9"
      />
    </svg>
  );
};
