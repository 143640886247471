import React, { useState } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { requestHeaders, BACKEND_URL } from "../../../../utils/headers";
import axiosInstance from "../../../../api/axios_instance";

const TrueAndFalse = (props) => {
  const { Item } = props;
  const staffNumber = Cookies.get("staff-number");

  const [answer, setAnswer] = useState(
    Item.value === undefined ? null : Item.value
  );
  const TrueSvg = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="26"
      viewBox="0 0 30 26"
      fill="none"
    >
      <path d="M29.84 5.16723L12.5258 25.7564C12.4665 25.8265 12.3936 25.8841 12.3114 25.9257C12.2292 25.9673 12.1393 25.992 12.0472 25.9984C11.9551 26.0047 11.8627 25.9926 11.7755 25.9627C11.6882 25.9328 11.608 25.8857 11.5395 25.8243L0.227141 15.7364C0.159382 15.676 0.104453 15.6028 0.0655644 15.5211C0.0266754 15.4394 0.0046064 15.3509 0.000647368 15.2606C-0.00331167 15.1704 0.0109184 15.0803 0.0425062 14.9955C0.0740941 14.9108 0.122406 14.8332 0.184619 14.7671L3.87379 10.8535C3.9952 10.7247 4.16233 10.6477 4.33993 10.6387C4.51753 10.6297 4.69172 10.6894 4.82575 10.8052L11.1314 16.255L25.3587 0.230647C25.4239 0.157203 25.5043 0.0985791 25.5945 0.0587802C25.6847 0.0189813 25.7825 -0.00105429 25.8812 4.27353e-05C25.9799 0.00113976 26.0772 0.0233433 26.1665 0.0651364C26.2558 0.10693 26.3349 0.167327 26.3984 0.242202L29.84 4.29451C29.9434 4.41684 30 4.57131 30 4.73087C30 4.89042 29.9434 5.04489 29.84 5.16723Z" />
    </svg>
  );

  const FalseSvg = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 22 22"
      fill="none"
    >
      <path d="M21.3944 18.4794L13.915 11L21.3944 3.52063C22.2019 2.71313 22.2019 1.41312 21.3944 0.605623C20.5869 -0.201874 19.2869 -0.201874 18.4794 0.605623L11 8.08499L3.52063 0.605623C2.71313 -0.201874 1.41312 -0.201874 0.605623 0.605623C-0.201874 1.41312 -0.201874 2.71313 0.605623 3.52063L8.08499 11L0.605623 18.4794C-0.201874 19.2869 -0.201874 20.5869 0.605623 21.3944C1.41312 22.2019 2.71313 22.2019 3.52063 21.3944L11 13.915L18.4794 21.3944C19.2869 22.2019 20.5869 22.2019 21.3944 21.3944C22.1961 20.5869 22.1961 19.2811 21.3944 18.4794Z" />
    </svg>
  );

  const handleTrueAndFalse = (value) => {
    setAnswer(value);
    Item.value = value;
    SendData(staffNumber, Item.id, value);
  };

  return (
    <span className="line-true-and-false-row">
      <TrueAndFalseItem
        value={true}
        answerClass={answer === true ? "active" : ""}
        Text={"صح"}
        Icon={TrueSvg}
        handleTrueAndFalse={handleTrueAndFalse}
      />
      <TrueAndFalseItem
        value={false}
        answerClass={answer === false ? "active" : ""}
        Text={"خطأ"}
        Icon={FalseSvg}
        handleTrueAndFalse={handleTrueAndFalse}
      />
    </span>
  );
};

export default TrueAndFalse;

const TrueAndFalseItem = (props) => {
  const { value, answerClass, Text, Icon, handleTrueAndFalse } = props;
  return (
    <span className={`line-true-and-false-row-item  ${answerClass}`}>
      <label>
        <input
          type="radio"
          name="radio"
          value={String(true)}
          onChange={() => {
            handleTrueAndFalse(value);
          }}
        />
        <span className="creacil">
          <span className="checkmark"></span>
        </span>
        {Text}
        {Icon}
      </label>
    </span>
  );
};
const SendData = async (staffNumber, surveyQuestionId, studentAnswer) => {
  const options = {
    method: "post",
    url: `${BACKEND_URL}Survey/SaveStaffSurveyAnswer`,
    headers: requestHeaders,
    data: [
      {
        staffNumber,
        surveyQuestionId: surveyQuestionId,
        staffAnswer: `${studentAnswer}`,
      },
    ],
  };
  await axios(options)
    .then(function (response) {})
    .catch(function (error) {});
};
