import React from "react";
import { Rating } from "react-simple-star-rating";
import axios from "axios";
import Cookies from "js-cookie";
import { requestHeaders, BACKEND_URL } from "../../../../utils/headers";

const RatingContener = (props) => {
  const { rating, setRating, Item } = props;
  const staffNumber = Cookies.get("staff-number");
  const handleRating = (e) => {
    Item.value = e;
    setRating(e);
    SendData(staffNumber, Item.id, e);
  };
  return (
    <div className="rating">
      <Rating
        onClick={handleRating}
        initialValue={rating}
        ratingValue={rating}
        size={30}
        rtl={true}
      />
    </div>
  );
};

export default RatingContener;
const SendData = async (staffNumber, surveyQuestionId, studentAnswer) => {
  const options = {
    method: "post",
    url: `${BACKEND_URL}Survey/SaveStaffSurveyAnswer`,
    headers: requestHeaders,
    data: [
      {
        staffNumber,
        surveyQuestionId: surveyQuestionId,
        staffAnswer: `${studentAnswer}`,
      },
    ],
  };
  await axios(options)
    .then(function (response) {})
    .catch(function (error) {});
};
