import Cookies from "js-cookie";
import ResultContainer from "../../components/system-sections/result/index";
import ResultEnd from "../../components/system-sections/result/result-end";
import { useEffect, useState } from "react";
import { requestHeaders, BACKEND_URL } from "../../utils/headers";

import axios from "axios";
import Loading from "../../components/loading/loading";
import axiosInstance from "../../api/axios_instance";

export default function EndPage() {
  const TitleSuccess = "تهانينا ، لقد اجتزت الاختبار بنجاح",
    TitleFail = "عفوا ، لقد رسبت في الاختبار";
  const [studentsGrade, setStudentsGrade] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const StudentId = localStorage.getItem("user-study-study");
    const TestId = Cookies.get("testid-study");

    GetData(StudentId, TestId, setStudentsGrade, setLoading);
  }, []);

  if (loading === false)
    return (
      <section className="system">
        <div className="container">
          <Loading />
        </div>
      </section>
    );

  return (
    <section className="system">
      <div className="container">
        <div className="system__content">
          <div className="system__content__top">
            <div className="system__content__top__subject border-left-top">
              <img src="/assets/icons/icon-chemistry.svg" alt="Logo" />
              <p>
                <span className="title__subject">
                  {studentsGrade.subjectName}
                </span>
                <span>-</span>
                <span>{studentsGrade.subjectCode}</span>
              </p>
            </div>
          </div>
          <ResultContainer
            // Title={studentsGrade.score >= "50" ? TitleSuccess : TitleFail}
            Title={""}
            EndData={"مجموع الدرجات النهائية  100"}
            Success={studentsGrade.isSuccedded}
            Number={studentsGrade.score}
          />
          <ResultEnd />
        </div>
      </div>
    </section>
  );
}

const GetData = async (StudentId, TestId, setStudentsGrade, setLoading) => {
  var data = new FormData();
  data.append("studentId", StudentId);
  data.append("testId", TestId);
  const options = {
    method: "post",
    url: `${BACKEND_URL}Test/CalculateStudentScore`,
    headers: requestHeaders,
    data,
  };
  await axiosInstance(options)
    .then(function (response) {
      setStudentsGrade(response.data.responseObject);
      setLoading(true);
    })
    .catch(function (error) {
      setLoading(true);
    });
};
