/* eslint-disable eqeqeq */
import Cookies from "js-cookie";
import React from "react";
import swal from "sweetalert";

const EvaluationButtons = (props) => {
  const { questions } = props;

  const NextStep = () => {
    for (let i = 0; i < questions.length; i++) {
      if (
        questions[i].value === "" ||
        questions[i].value === null ||
        questions[i].value === undefined
      ) {
        swal("يجب الاجابة على جميع الاسئلة", {
          icon: "warning",
          timer: 2000,
          className: "swal-contact-us",
        });
        return false;
      }
    }
    document.getElementById("StaffExitModalButton").click();
  };

  return (
    <div className="questions__content__evaluation-exam-button">
      <button
        type="button"
        className="btn btn-back"
        style={{ opacity: 0, pointerEvents: "none" }}
        onClick={() => {}}
      >
        السابق
      </button>

      <button
        type="button"
        className="btn btn-next btn-send"
        onClick={NextStep}
      >
        <>{"خروج"}</>
      </button>
      <button
        id="StaffExitModalButton"
        style={{ display: "none" }}
        data-bs-toggle="modal"
        data-bs-target={`#StaffExitModal`}
      ></button>
      <StaffExitModal />
    </div>
  );
};

export default EvaluationButtons;
const StaffExitModal = () => {
  return (
    <div
      className="modal fade modal-edite-exams"
      id={`StaffExitModal`}
      tabIndex="-1"
      aria-labelledby="staffExitModal"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div style={{ color: "#666" }}>
            <h3>الخروج من الاستبيان</h3>
            <br />
            <p>هل انت متاكد من رغبتك فى انهاء الاستبيان؟</p>
          </div>
          <div className="form-group-button">
            <button
              style={{
                width: "162px",
                height: "51px",
                background: "#ececec",
              }}
              type="button"
              className="btn btn-back"
              data-bs-dismiss="modal"
            >
              لا
            </button>
            <button
              type="button"
              style={{
                width: "162px",
                height: "51px",
                // background: "#ececec",
              }}
              className="btn btn-dark"
              onClick={(e) => {
                e.preventDefault();
                Cookies.remove("staff-number");
                window.location.reload();
              }}
            >
              نعم
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
