import React from "react";
import LoginTitle from "./components/title";
import { LoginForm } from "./components/form";

export default function StaffSingIn() {
  return (
    <>
      <section className="login">
        <div className="login__content">
          <div className="login__content__decriphon">
            <LoginTitle />
            <LoginForm />
          </div>
        </div>
      </section>
    </>
  );
}
