/* eslint-disable no-lone-blocks */
/* eslint-disable eqeqeq */
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import Cookies from "js-cookie";
import axiosInstance, { signout } from "../../../../api/axios_instance";
import { BACKEND_URL, requestHeaders } from "../../../../utils/headers";
import swal from "sweetalert";

const CountdownCircle = (props) => {
  const { setLoad, Questions, step, duration } = props;
  let location = useLocation();
  const navigate = useNavigate();
  const [testEndTime, setTestEndTime] = useState(
    Cookies.get("testEndTime-study")
  );
  const testID = Cookies.get("testid-study");

  // const StartDay = new Date();
  // const EndDay = testEndTime ? new Date(testEndTime) : new Date();
  // const timeLeft = EndDay - new Date();
  const [secondsTime, setSecondsTime] = useState(
    ((testEndTime ? new Date(testEndTime) : new Date()) - new Date()) / 1000
  );

  const timeEndHandler = async () => {
    const test = await getTestTime(testID);
    const StudentId = localStorage.getItem("user-study-study");
    const endTime = new Date(test?.data?.responseObject?.endDate);
    // const endTime = new Date();
    const nowTime = new Date();
    if (nowTime >= endTime) {
      setLoad(false);
      SendData(StudentId, testID, step, Questions);
      swal("لقد انتهي وقت الاختبار", {
        icon: "warning",
        timer: 2000,
        className: "swal-contact-us",
      }).then(() => {
        navigate("/end-questions");
      });
    } else {
      setTestEndTime(test?.data?.responseObject?.endDate);
      Cookies.set("testEndTime-study", test?.data?.responseObject?.endDate);
    }
  };

  useEffect(() => {
    const interval = setInterval(async () => {
      const test = await getTestTime(testID);
      const endTime = test?.data?.responseObject?.endDate;
      setSecondsTime(
        ((testEndTime ? new Date(endTime) : new Date()) - new Date()) / 1000
      );
      if (endTime && endTime !== testEndTime) {
        setTestEndTime(endTime);

        Cookies.set("testEndTime-study", endTime);
      }
    }, 60000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="system__content__top__countdown-circle border-right-top">
      {location.pathname == "/end-questions" ? (
        <></>
      ) : (
        <>
          <div className="d-flex gap-2">
            <UrgeWithPleasureComponent
              duration={duration}
              key={secondsTime}
              Seconds={secondsTime}
              handleEnd={timeEndHandler}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default CountdownCircle;

const UrgeWithPleasureComponent = (props) => {
  const { Seconds, handleEnd, duration } = props;
  const [relTimeSeconds, setRelTimeSeconds] = useState(Seconds);
  useEffect(() => {
    if (relTimeSeconds <= 0) handleEnd();
    const intervals = setInterval(() => {
      if (relTimeSeconds <= 0) handleEnd();
    }, 1000);
    return () => clearInterval(intervals);
  }, [relTimeSeconds]);
  return (
    <>
      <CountdownCircleTimer
        isPlaying
        duration={duration}
        initialRemainingTime={Seconds}
        colors={["#68c272", "#A30000", "#A30000"]}
        colorsTime={[300, 120, 0]}
        strokeWidth={5}
        size={50}
      >
        {({ remainingTime }) => {
          setRelTimeSeconds(remainingTime);
          return null; // Replace with the JSX element you want to render
        }}
      </CountdownCircleTimer>
      <ShowTime TimeSeconds={relTimeSeconds} />
    </>
  );
};

const ShowTime = (props) => {
  const { TimeSeconds } = props;
  function convertSeconds(seconds) {
    const hours = Math.floor(seconds / 3600); // Calculate hours
    const minutes = Math.floor((seconds % 3600) / 60); // Calculate remaining minutes
    const remainingSeconds = seconds % 60; // Calculate remaining seconds

    return {
      hours: hours,
      minutes: minutes,
      seconds: remainingSeconds,
    };
  }
  function twoDigits(num) {
    return String(num).padStart(2, "0");
  }
  const { hours, minutes, seconds } = convertSeconds(TimeSeconds);

  return (
    <div className="countdown-circle-text">
      <p className="text">متبقي</p>
      <p className="time">
        <span className="time__number">{`${twoDigits(hours)}:${twoDigits(
          minutes
        )}:${twoDigits(seconds)}`}</span>
        <span className="menute">دقيقة</span>
      </p>
    </div>
  );
};

const getTestTime = (id) => {
  const options = {
    method: "post",
    url: `${BACKEND_URL}Test/GetTestById`,
    headers: requestHeaders,
    data: { id },
  };
  return axiosInstance(options);
};
// const ShowTime = (props) => {
//   const { TimeSeconds } = props;
//   const seconds =
//     TimeSeconds % 60 === 0
//       ? 0
//       : TimeSeconds % 60 > 9
//       ? TimeSeconds % 60
//       : "0" + (TimeSeconds % 60);
//   {
//     /* Get minutes By Seconds */
//   }
//   const minutes =
//     Math.floor(TimeSeconds / 60) === 0
//       ? 0
//       : Math.floor(TimeSeconds / 60) > 9
//       ? Math.floor(TimeSeconds / 60)
//       : "0" +
//         (Math.floor(TimeSeconds / 60) > 60
//           ? "00"
//           : Math.floor(TimeSeconds / 60));
//   const newMinutes = Number(minutes) % 60;
//   const hours =
//     Math.floor(TimeSeconds / 3600) === 0
//       ? 0
//       : Math.floor(TimeSeconds / 3600) > 9
//       ? Math.floor(TimeSeconds / 3600)
//       : "0" + Math.floor(TimeSeconds / 3600);
//   const time = `${hours === 0 ? "00" : hours}:${
//     newMinutes === 0 ? "00" : newMinutes < 10 ? "0" + newMinutes : newMinutes
//   }:${seconds === 0 ? "00" : seconds}`;

//   return (
//     <div className="countdown-circle-text">
//       <p className="text">متبقي</p>
//       <p className="time">
//         <span className="time__number">{`${twoDigits(hours)}:${twoDigits(
//           minutes
//         )}:${twoDigits(seconds)}`}</span>
//         <span className="menute">دقيقة</span>
//       </p>
//     </div>
//   );
// };

const SendData = async (StudentId, TestId, Step, Quetsions) => {
  var data = new FormData();
  data.append("studentId", StudentId);
  data.append("testId", TestId);
  for (let i = 0; i < Quetsions[Step].EndAnswer.length; i++) {
    data.append(
      `testAnswers[${i}].answerId`,
      Quetsions[Step].EndAnswer[i].answerId
    );
    data.append(
      `testAnswers[${i}].questionId`,
      Quetsions[Step].EndAnswer[i].questionId
    );
  }

  const options = {
    method: "post",
    url: `${BACKEND_URL}Test/SaveStudentAnswer`,
    headers: requestHeaders,
    data,
  };
  await axiosInstance(options)
    .then(function (response) {})
    .catch(function (error) {});
};
