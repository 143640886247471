import { BrowserRouter, Route, Routes } from "react-router-dom";
import ScrollToTop from "./components/helpers/index.jsx";
import Layout from "./components/layout/index.jsx";
import Home from "./pages/home/index.jsx";
import SingIn from "./pages/singin/index.jsx";
import Details from "./pages/details/index.jsx";
import Questions from "./pages/questions/index.jsx";
import EndQuestions from "./pages/end-questions/index.jsx";
import Evaluation from "./pages/evaluation/index.jsx";
import EndPage from "./pages/end-page/index.jsx";
import AuthGuard from "./components/guards/auth.guard.jsx";
import StaffSingIn from "./pages/singin-staff/index.jsx";
import StaffGuard from "./components/guards/staff.guard .jsx";
import StaffSurvey from "./pages/staff-survey/index.jsx";

function App() {
  return (
    <main>
      <BrowserRouter>
        <ScrollToTop>
          <Layout>
            <Routes>
              <Route
                path="/signin"
                element={
                  <AuthGuard>
                    <SingIn />
                  </AuthGuard>
                }
                exact
              />
              <Route
                path="/"
                element={
                  <AuthGuard>
                    <Home />
                  </AuthGuard>
                }
                exact
              />
              <Route
                path="/details/:id"
                element={
                  <AuthGuard>
                    <Details />
                  </AuthGuard>
                }
              />
              <Route
                path="/questions"
                element={
                  <AuthGuard>
                    <Questions />
                  </AuthGuard>
                }
              />
              <Route
                path="/end-questions"
                element={
                  <AuthGuard>
                    <EndQuestions />
                  </AuthGuard>
                }
              />
              <Route
                path="/evaluation"
                element={
                  <AuthGuard>
                    <Evaluation />
                  </AuthGuard>
                }
              />
              <Route
                path="/end-page"
                element={
                  <AuthGuard>
                    <EndPage />
                  </AuthGuard>
                }
              />
              <Route
                path="/staff-signin"
                element={
                  <StaffGuard>
                    <StaffSingIn />
                  </StaffGuard>
                }
              />
              <Route
                path="/staff-survey"
                element={
                  <StaffGuard>
                    <StaffSurvey />
                  </StaffGuard>
                }
              />
            </Routes>
          </Layout>
        </ScrollToTop>
      </BrowserRouter>
    </main>
  );
}

export default App;
