import ListQuestions from "./list-questions";
import { requestHeaders, BACKEND_URL } from "../../../../utils/headers";
import Cookies from "js-cookie";
import axios from "axios";
import swal from "sweetalert";
import axiosInstance from "../../../../api/axios_instance";
import { useEffect } from "react";
import { useNavigate } from "react-router";

const QuestionsTrueAndFalse = (props) => {
  const { Quetsions, Step, setStep, setActive, degrees } = props;

  return (
    <>
      <div className="questions__content__container">
        <div className="questions__content__container__content">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h3>سؤال رقم {Step + 1}</h3>
            <h3
              style={{
                padding: "10px",
                borderRadius: "10px",
                background: "#ececec",
              }}
            >
              {(degrees?.[Quetsions?.[Step]?.degreeType] || 0).toFixed(2)} درجة
            </h3>
          </div>
          <h3>{Quetsions[Step].question[0].questionName}</h3>
          <span className="line"></span>
          <div className="questions__content__container__content__answers-two">
            {Quetsions[Step].answers.map((item, index) => {
              return (
                <TrueAndFalseAnswers
                  item={item}
                  index={index}
                  key={index}
                  Question={Quetsions[Step].question[0]}
                  ArrayAnswerssData={Quetsions[Step].answers}
                  Step={Step}
                  Quetsions={Quetsions}
                />
              );
            })}
          </div>
        </div>

        <ListQuestions Quetsions={Quetsions} Step={Step} />
      </div>
      <QuestionsEnd
        Quetsions={Quetsions}
        Step={Step}
        setStep={setStep}
        setActive={setActive}
      />
    </>
  );
};

export default QuestionsTrueAndFalse;

const TrueAndFalseAnswers = (props) => {
  const { item, index, Question, ArrayAnswerssData, Quetsions, Step } = props;

  // useEffect(() => {
  //   if(Quetsions[Step].isAnswerSelected)
  //     Quetsions[Step].EndAnswer[0].questionId = Question.questionId
  //   Quetsions[Step].EndAnswer[0].answerId =
  // }, [Quetsions]);

  const handleChange = (e) => {
    e.preventDefault();
    RemoveData(ArrayAnswerssData);
    e.currentTarget.classList.add("active");
    ArrayAnswerssData[index].isSelectedByStudent = true;
    Quetsions[index].isSelect = true;
    Quetsions[Step].EndAnswer[0].questionId = Question.questionId;
    Quetsions[Step].EndAnswer[0].answerId = item.answerId;
  };

  return (
    <button
      type="button"
      className={`questions__content__container__content__answers-two__item ${
        item.answerId === Quetsions[Step].EndAnswer[0].answerId ? "active" : ""
      }`}
      onClick={(e) => {
        handleChange(e);
      }}
    >
      <label>
        <span className="creacil">
          <span className="checkmark"></span>
        </span>
        {item.answerName}
        {item.answerName === "صح" ? <IconTrue /> : <IconFalse />}
      </label>
    </button>
  );
};

const QuestionsEnd = (props) => {
  const { Quetsions, Step, setStep, setActive } = props;
  const navigate = useNavigate();

  const TestId = Cookies.get("testid-study");
  const handleNext = () => {
    if (
      Quetsions[Step].EndAnswer[0].questionId === "" ||
      Quetsions[Step].EndAnswer[0].answerId === ""
    ) {
      swal("يجب عليك اختيار اجابة", {
        icon: "warning",
        timer: 1000,
        className: "swal-contact-us",
      });
      return false;
    } else {
      CheckStep(
        Quetsions,
        Step,
        setStep,
        Quetsions[Step].EndAnswer,
        setActive,
        navigate
      );
    }
  };

  return (
    <div className="questions__content__container__end">
      <button
        className="btn btn-back"
        type="button"
        style={{
          opacity: Step == 0 ? "0" : "1",
          pointerEvents: Step == 0 ? "none" : "auto",
        }}
        onClick={() => {
          if (Step === 0) {
            // navigate(`/details/${TestId}`);
          } else {
            setStep(Step - 1);
          }
        }}
      >
        <IconBack />
        <span className="text">السابق</span>
      </button>
      <button
        className="btn btn-next"
        type="button"
        id="force-next"
        onClick={() => {
          handleNext();
        }}
      >
        <span className="text">التالى</span>
        <IconNext />
      </button>
    </div>
  );
};

const IconTrue = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="26"
      viewBox="0 0 30 26"
      fill="none"
    >
      <path d="M29.84 5.16723L12.5258 25.7564C12.4665 25.8265 12.3936 25.8841 12.3114 25.9257C12.2292 25.9673 12.1393 25.992 12.0472 25.9984C11.9551 26.0047 11.8627 25.9926 11.7755 25.9627C11.6882 25.9328 11.608 25.8857 11.5395 25.8243L0.227141 15.7364C0.159382 15.676 0.104453 15.6028 0.0655644 15.5211C0.0266754 15.4394 0.0046064 15.3509 0.000647368 15.2606C-0.00331167 15.1704 0.0109184 15.0803 0.0425062 14.9955C0.0740941 14.9108 0.122406 14.8332 0.184619 14.7671L3.87379 10.8535C3.9952 10.7247 4.16233 10.6477 4.33993 10.6387C4.51753 10.6297 4.69172 10.6894 4.82575 10.8052L11.1314 16.255L25.3587 0.230647C25.4239 0.157203 25.5043 0.0985791 25.5945 0.0587802C25.6847 0.0189813 25.7825 -0.00105429 25.8812 4.27353e-05C25.9799 0.00113976 26.0772 0.0233433 26.1665 0.0651364C26.2558 0.10693 26.3349 0.167327 26.3984 0.242202L29.84 4.29451C29.9434 4.41684 30 4.57131 30 4.73087C30 4.89042 29.9434 5.04489 29.84 5.16723Z" />
    </svg>
  );
};
const IconFalse = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 22 22"
      fill="none"
    >
      <path d="M21.3944 18.4794L13.915 11L21.3944 3.52063C22.2019 2.71313 22.2019 1.41312 21.3944 0.605623C20.5869 -0.201874 19.2869 -0.201874 18.4794 0.605623L11 8.08499L3.52063 0.605623C2.71313 -0.201874 1.41312 -0.201874 0.605623 0.605623C-0.201874 1.41312 -0.201874 2.71313 0.605623 3.52063L8.08499 11L0.605623 18.4794C-0.201874 19.2869 -0.201874 20.5869 0.605623 21.3944C1.41312 22.2019 2.71313 22.2019 3.52063 21.3944L11 13.915L18.4794 21.3944C19.2869 22.2019 20.5869 22.2019 21.3944 21.3944C22.1961 20.5869 22.1961 19.2811 21.3944 18.4794Z" />
    </svg>
  );
};

const IconNext = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
    >
      <path
        d="M19 8.3125H4.54812L11.1803 1.68031L9.5 0L0 9.5L9.5 19L11.1803 17.3197L4.54812 10.6875H19V8.3125Z"
        fill="white"
      />
    </svg>
  );
};

const IconBack = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
    >
      <path
        d="M0 10.6875L14.4519 10.6875L7.81969 17.3197L9.5 19L19 9.5L9.5 0L7.81969 1.68031L14.4519 8.3125L0 8.3125V10.6875Z"
        fill="#B7BBC9"
      />
    </svg>
  );
};

const CheckStep = (
  Quetsions,
  Step,
  setStep,
  Arrayquestion,
  setActive,
  navigate
) => {
  const StudentId = localStorage.getItem("user-study-study");
  const TestId = Cookies.get("testid-study");

  SendData(
    StudentId,
    TestId,
    Step,
    setStep,
    Quetsions,
    Arrayquestion,
    setActive,
    navigate
  );
};

const SendData = async (
  StudentId,
  TestId,
  Step,
  setStep,
  Quetsions,
  Arrayquestion,
  setActive,
  navigate
) => {
  var data = new FormData();
  data.append("studentId", StudentId);
  data.append("testId", TestId);
  for (let i = 0; i < Arrayquestion.length; i++) {
    data.append(`testAnswers[${i}].questionId`, Arrayquestion[i].questionId);
    data.append(`testAnswers[${i}].answerId`, Arrayquestion[i].answerId);
  }

  const options = {
    method: "post",
    url: `${BACKEND_URL}Test/SaveStudentAnswer`,
    headers: requestHeaders,
    data,
  };
  await axiosInstance(options)
    .then(function (response) {
      if (response.data.responseStatus.description === "Success") {
        if (Step === Quetsions.length - 1) {
          navigate("/end-questions");
        } else {
          const number = +Step;
          setStep(number + 1);
          setActive(true);
        }
      } else {
        const Error = response.data.responseStatus.errors[0].errorMessage;
        swal(Error, {
          icon: "error",
          timer: 1000,
          className: "swal-contact-us",
        });
      }
    })
    .catch(function (error) {
      const Error = error.response.data.Errors.TestAnswers[1];
      swal(Error, {
        icon: "error",
        timer: 1000,
        className: "swal-contact-us",
      });
    });
};
const RemoveData = (ArrayAnswerssData) => {
  const Array = document.querySelectorAll(
    ".questions__content__container__content__answers-two__item"
  );
  Array.forEach((element) => {
    element.classList.remove("active");
  });
  ArrayAnswerssData.forEach((element) => {
    element.isSelectedByStudent = false;
  });
};
