import React from "react";
import EvaluationLine from "./evaluation-line";

const EvaluationContainer = (props) => {
  const { questions } = props;

  return (
    <>
      <ul>
        {questions.map((item, index) => {
          return <EvaluationLine Item={item} key={item.questionId} />;
        })}
      </ul>
    </>
  );
};

export default EvaluationContainer;
