//const matchingQuestions=[];

//const nonMatchingQuestions=[];
const Questions = [];
export const QuestionsCheck = [];

function removeDuplicates(Questions, setData) {
  const ids = Questions.map(({ id }) => id);
  const filtered = Questions.filter(
    ({ id }, index) => !ids.includes(id, index + 1)
  );

  // const sorted = filtered.sort((a, b) => a.order - b.order);
  setData(filtered);
}
const Types = {
  3: "matchingDegree",
  2: "trueOrFalseDegree",
  1: "multipleChoiceDegree",
};

export function AddQuestions(matchingQuestions, nonMatchingQuestions, setData) {
  if (nonMatchingQuestions.length > 0) {
    Questions.push(
      ...nonMatchingQuestions.map((q) => ({
        id: q.questionId,
        order: q.questionOrder,
        type: q.answerTypeId,
        question: [
          {
            questionId: q.questionId,
            questionName: q.questionName,
          },
        ],
        answers: q.questionAnswers,
        degreeType: Types[q.answerTypeId],
        isSelect: false,
        EndAnswer: [
          {
            questionId: q.questionId,
            answerId: q.isAnswerSelected
              ? q.questionAnswers.find((itm) => itm.isSelectedByStudent)
                  ?.answerId
              : "",
          },
        ],
      }))
      // .sort((a, b) => a.order - b.order)
    );
  }
  if (matchingQuestions.length > 0) {
    Questions.push(
      ...matchingQuestions.map((q) => ({
        id: q.questions[0].questionId,
        order: q.questionOrder,
        type: q.answers[0].answerTypeId,
        question: q.questions,
        answers: q.answers,
        isSelect: q.answers.some((itm) => itm.selectedAnswer),
        degreeType: Types[q.answers[0].answerTypeId],
        EndAnswer: q.answers.map((answer) => ({
          questionId: answer?.selectedAnswer,
          answerId: answer.answerId,
          value:
            q.questions.findIndex(
              (obj) => obj.questionId === answer?.selectedAnswer
            ) + 1,
        })),
      }))
      // .sort((a, b) => a.order - b.order)
    );
  }

  removeDuplicates(Questions, setData);
  /*
  matchingQuestions.forEach((question) => {
    Questions.push(question);
  });
  nonMatchingQuestions.forEach((question) => {
    Questions.push(question);
  });
  */
}
