import React from "react";

const LoginTitle = (props) => {
  return (
    <div className="login__title">
      <p>اهلا ،</p>
      <p>شارك برايك !</p>
    </div>
  );
};

export default LoginTitle;
