/* eslint-disable no-lone-blocks */
"use client";
import { useState } from "react";
import { CountdownCircleTimer } from "react-countdown-circle-timer";

const HomeEnd = (props) => {
  const { Item } = props;
  const StartDay = new Date(),
    EndDay = new Date(Item.testDateTime);
  const timeLeft = EndDay - StartDay;
  const secondsTime = timeLeft / 1000;
  const [isNow, setIsNow] = useState(secondsTime <= 0 ? true : false);
  return (
    <>
      {isNow ? (
        <div className="home__content__discription__end">
          <p className="title">الاختبار الأن</p>
        </div>
      ) : (
        <div className="home__content__discription__end">
          <p className="title">يبدأ الاختبار بعد</p>
          <UrgeWithPleasureComponent
            Seconds={secondsTime}
            isNow={isNow}
            setIsNow={setIsNow}
          />
        </div>
      )}
    </>
  );
};

export default HomeEnd;

const UrgeWithPleasureComponent = (props) => {
  const { Seconds, isNow, setIsNow } = props;
  const [relTimeSeconds, setRelTimeSeconds] = useState(1);

  return (
    <>
      <div className="countdown">
        <CountdownCircleTimer
          isPlaying
          duration={Seconds}
          colors={["#004777", "#F7B801", "#A30000", "#A30000"]}
          colorsTime={[7, 5, 2, 0]}
          strokeWidth={5}
          size={50}
        >
          {({ remainingTime }) => {
            setRelTimeSeconds(remainingTime);
            if (remainingTime <= 0 && !isNow) setIsNow(true);
            return null; // Replace with the JSX element you want to render
          }}
        </CountdownCircleTimer>
      </div>
      <ShowTime TimeSeconds={relTimeSeconds} />
    </>
  );
};

const ShowTime = (props) => {
  const { TimeSeconds } = props;
  function convertSeconds(seconds) {
    const hours = Math.floor(seconds / 3600); // Calculate hours
    const minutes = Math.floor((seconds % 3600) / 60); // Calculate remaining minutes
    const remainingSeconds = seconds % 60; // Calculate remaining seconds

    return {
      hours: hours,
      minutes: minutes,
      seconds: remainingSeconds,
    };
  }
  function twoDigits(num) {
    return String(num).padStart(2, "0");
  }
  const { hours, minutes, seconds } = convertSeconds(TimeSeconds);

  return (
    <p className="time">
      <span className="time__number">{`${twoDigits(hours)}:${twoDigits(
        minutes
      )}:${twoDigits(seconds)}`}</span>
      <span className="time__text">دقيقة</span>
    </p>
  );
};
