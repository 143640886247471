import Cookies from "js-cookie";
import { useState, useEffect } from "react";
import { requestHeaders, BACKEND_URL } from "../../utils/headers";
import axios from "axios";
import Loading from "../../components/loading/loading";
import EvaluationContent from "./components";
import { AddEvaluation } from "./components/value";
import axiosInstance from "../../api/axios_instance";

export default function Evaluation() {
  const [evaluation, setEvaluation] = useState([]);
  const [loading, setLoading] = useState(false);
  const [Questions, setQuestions] = useState([]);

  useEffect(() => {
    const TestId = Cookies.get("testid-study");
    const studentId = localStorage.getItem("user-study-study");
    GetData(TestId, studentId, setEvaluation, setLoading, setQuestions);
  }, []);

  if (loading === false)
    return (
      <section className="questions">
        <div className="container">
          <Loading />
        </div>
      </section>
    );

  return (
    <section className="questions">
      <div className="container">
        {evaluation?.length && (
          <EvaluationContent
            EvaluationData={evaluation}
            Questions={Questions}
          />
        )}
      </div>
    </section>
  );
}

const GetData = async (
  TestId,
  studentId,
  setEvaluation,
  setLoading,
  setQuestions
) => {
  var data = new FormData();
  data.append("testId", TestId);
  data.append("studentId", studentId);

  const options = {
    method: "post",
    url: `${BACKEND_URL}Survey/GetSurveyQuestionsByTest`,
    headers: requestHeaders,
    data,
  };
  await axiosInstance(options)
    .then(function (response) {
      if (!response.data.responseObject?.length)
        window.location.href = "/end-page";
      else {
        setEvaluation(response.data.responseObject);
        AddEvaluation(response.data.responseObject, setQuestions);
        setLoading(true);
      }
    })
    .catch(function (error) {
      setLoading(true);
    });
};
