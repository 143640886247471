import React, { useEffect, useState } from "react";
import DetailsData from "./components/details-data";
import DetailsEnd from "./components/details-end";
import DetailsTop from "./components/details-top";
import Cookies from "js-cookie";

import axios from "axios";
import { requestHeaders, BACKEND_URL } from "../../utils/headers";
import Loading from "../../components/loading/loading";
import { useParams } from "react-router";
import axiosInstance from "../../api/axios_instance";

const Details = () => {
  const [data, setData] = useState([]);
  const [degrees, setDegrees] = useState({});

  const [loading, setLoading] = useState(false);
  const { id } = useParams();

  useEffect(() => {
    const StudentId = localStorage.getItem("user-study-study");
    getDegrees(StudentId, id, setDegrees);
    GetData(StudentId, setData, setLoading);
  }, []);

  if (loading === false)
    return (
      <section className="home">
        <Loading />
      </section>
    );

  return (
    <section className="details">
      <div className="container">
        <DetailsContent data={data.find((item) => item.testId === id)} />
      </div>
    </section>
  );
};

export default Details;

const DetailsContent = (props) => {
  const { data } = props;
  const now = new Date();

  return (
    <div className="details__content">
      <DetailsTop
        Title={data.subjectName}
        Code={data.subjectCode}
        CourseName={data.classRoomName}
      />
      <DetailsData
        data={data}
        matchingDegrees={data.matchingTotalDegree}
        choiceDegrees={data.choiceTotalDegree}
        trueFalseDegrees={data.trueFalseTotalDegree}
        Subject={data.subjectName}
        Code={data.subjectCode}
        NumberOfQuestions={data.numberOfQuestions}
        TheDate={data.testDateTime}
        TestDuration={`${data.testDurationInMinutes} دقيقة`}
        TestStartTime={data.testStartTime}
        TestEndTime={data.testEndTime}
      />
      <DetailsEnd show={data.startDate && data.startDate <= now} />
    </div>
  );
};

const GetData = async (StudentId, setData, setLoading) => {
  var data = new FormData();
  data.append("id", StudentId);
  const options = {
    method: "post",
    url: `${BACKEND_URL}Test/GetAvailableTests`,
    headers: requestHeaders,
    data,
  };
  await axiosInstance(options)
    .then(function (response) {
      setData(
        response.data.responseObject.map((itm) => ({
          ...itm,
          startDate: new Date(itm.testDateTime),
        }))
      );
      setLoading(true);
    })
    .catch(function (error) {
      setLoading(true);
    });
};
const getDegrees = async (StudentId, TestId, setDegrees) => {
  var data = new FormData();
  data.append("studentId", StudentId);
  data.append("testId", TestId);
  const options = {
    method: "post",
    url: `${BACKEND_URL}Test/GetQuestionTypesDegrees`,
    headers: requestHeaders,
    data,
  };
  await axiosInstance(options).then((response) => {
    setDegrees(response.data.responseObject);
  });
};

// const getTestData = (id) => {
//   const options = {
//     method: "post",
//     url: `${BACKEND_URL}Test/GetTestById`,
//     headers: requestHeaders,
//     data: { id },
//   };
//   return axiosInstance(options);
// };
